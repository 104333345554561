import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PostCardModern from '../../../components/post-card-modern/post-card-modern';
import { BlogPostsWrapper, PostGrid, PostRow } from './style';

type PostsProps = {};

const Posts: React.FunctionComponent<PostsProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allSitePage(filter: { path: { eq: "/page/1" } }) {
        nodes {
          context {
            numPages
            currentPage
          }
        }
      }
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: ASC }
        limit: 11
      ) {
        totalCount
        edges {
          node {
            excerpt(pruneLength: 300)
            fields {
              slug
            }
            frontmatter {
              date(formatString: "DD [<span>] MMM [</span>]")
              title
              tags
              link
              cover {
                childImageSharp {
                  fluid(maxWidth: 1170, maxHeight: 690, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const Posts = Data.allMarkdownRemark.edges;

  return (
    <BlogPostsWrapper>
      <PostRow>
      {Posts.map(({ node }: any) => {
        const title = node.frontmatter.title || node.fields.slug;
        return (
          <PostGrid key={node.fields.slug}>
          <PostCardModern
            key={node.fields.slug}
            title={title}
            image={
              node.frontmatter.cover == null
                ? null
                : node.frontmatter.cover.childImageSharp.fluid
            }
            url={node.fields.slug}
            link={node.frontmatter.link}
            date={node.frontmatter.date}
            />
            </PostGrid>
        );
      })}
      </PostRow>

    </BlogPostsWrapper>
  );
};

export default Posts;
